import { useTranslate } from '/machinery/I18n'

import { Button } from '/features/pageOnly/menu/buildingBlocks/Button'

import iconHamburger from '/images/icons/icon-hamburger.raw.svg'

export function Hamburger({ onClick }) {
  const { __ } = useTranslate()

  return (
    <Button
      label={__`menu`}
      icon={iconHamburger}
      dataX='click-to-open-menu'
      {...{ onClick }}
    />
  )
}
