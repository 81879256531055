import { useFloating, useDismiss, useInteractions, useHover, autoUpdate, shift, useMergeRefs } from '@floating-ui/react'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useElementSize } from '@kaliber/use-element-size'

import { useTranslate } from '/machinery/I18n'

import { useNavigationContext } from '/features/pageOnly/menu/NavigationContext'
import { Hamburger } from '/features/pageOnly/menu/buildingBlocks/Hamburger'
import { Logo } from '/features/pageOnly/menu/buildingBlocks/Logo'
import { ExpandHorizontal } from '/features/buildingBlocks/Expand'
import { SubmenuDesktop } from '/features/pageOnly/menu/buildingBlocks/Submenu'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'
import { LanguageSwitch } from '/features/pageOnly/menu/buildingBlocks/LanguageSwitch'
import { ShowSkillsMatchPortalFlowButton } from '/features/pageOnly/skillsMatch/buildingBlocks/ShowSkillsMatchPortalFlowButton'

import mediaStyles from '/cssGlobal/media.css'
import styles from './MenuDesktop.css'

export function MenuDesktop({
  items,
  logoHref,
  showSkillsMatchButton,
  skillsMatchUserSelectionCount,
  pageButtons = [],
  fixedButtons = []
}) {
  const { menuIsExpanded } = useNavigationContext()

  return (
    <nav className={styles.component}>
      <div className={cx(styles.navBarContainer, menuIsExpanded && styles.menuIsExpanded)}>
        <NavBar
          layoutClassName={styles.navBarLayout}
          {...{
            items,
            logoHref,
            pageButtons,
            fixedButtons,
            showSkillsMatchButton,
            skillsMatchUserSelectionCount
          }}
        />
      </div>
    </nav>
  )
}

function NavBar({
  items,
  logoHref,
  pageButtons,
  fixedButtons,
  showSkillsMatchButton,
  skillsMatchUserSelectionCount,
  layoutClassName = undefined
}) {
  const { menuIsExpanded } = useNavigationContext()

  return (
    <div className={cx(styles.componentNavBar, layoutClassName)}>
      <Navigation layoutClassName={styles.navigationLayout} {...{ items, logoHref }} />
      <ActionMenu layoutClassName={styles.actionMenuLayout} {...{ pageButtons, fixedButtons, showSkillsMatchButton, skillsMatchUserSelectionCount }} />
      {menuIsExpanded && <Background layoutClassName={styles.backgroundLayout} />}
    </div>
  )
}

function Background({ layoutClassName = undefined }) {
  const { activeSubmenu } = useNavigationContext()
  const { active, submenuHeight } = activeSubmenu
  const height = active ? submenuHeight : 0

  return <span style={{ '--background-height': `${height}px` }} className={cx(styles.componentBackground, layoutClassName)} />
}

function Navigation({ items, logoHref, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentNavigation, layoutClassName)}>
      <div className={styles.logoContainer}>
        <Logo href={logoHref} layoutClassName={styles.logoLayout} />
      </div>
      <Menu layoutClassName={styles.menuLayout} {...{ items }} />
    </div>
  )
}

function Menu({ items, layoutClassName = undefined }) {
  const { menuIsExpanded } = useNavigationContext()

  return (
    <div className={cx(styles.componentMenu, menuIsExpanded && styles.menuIsExpanded, layoutClassName)}>
      <ul className={styles.menu}>
        {items.map((item, i) => (
          <li key={i} className={styles.menuItem}>
            <MenuItem layoutClassName={styles.itemLayout} {...{ item }} />
          </li>
        ))}
      </ul>
    </div>
  )
}

function MenuItem({ item, layoutClassName }) {
  switch (item?._type) {
    case 'referenceWithLabel': return <LinkItem {...{ item, layoutClassName }} />
    case 'submenu': return <SubmenuItem {...{ item, layoutClassName }} />
    default: return null
  }
}
function LinkItem({ item, layoutClassName = undefined }) {
  const { href, label, isActive } = item

  return (
    <a
      data-x='link-in-menu'
      className={cx(
        styles.componentLinkItem,
        isActive && styles.isActive,
        layoutClassName
      )}
      {...{ href }}
    >
      <span className={styles.label}>{label}</span>
    </a>
  )
}

function SubmenuItem({ item, layoutClassName = undefined }) {
  const { activeSubmenu } = useNavigationContext()
  const { _key: id, label, submenuItems, isActive } = item
  const thisSubmenuIsActive = id === activeSubmenu.id && activeSubmenu.active
  const { ref: submenuRef, size: { height: submenuHeight } } = useElementSize()
  const { refs, getReferenceProps, getFloatingProps, style } = useFloatingProps({ id, submenuHeight })

  return (
    <div ref={refs.setReference} {...getReferenceProps()}
      className={cx(
        styles.componentSubmenuItem,
        (isActive || thisSubmenuIsActive) && styles.isActive,
        layoutClassName
      )}>

      <span className={styles.label}>{label}</span>

      <div
        ref={useMergeRefs([refs.setFloating, submenuRef])}
        className={cx(styles.dropdown, thisSubmenuIsActive && styles.isActive)}
        {...getFloatingProps()}
        {...{ style }}
      >
        <SubmenuDesktop items={submenuItems} />
      </div>
    </div>
  )
}

function ActionMenu({
  pageButtons,
  fixedButtons,
  skillsMatchUserSelectionCount,
  showSkillsMatchButton,
  layoutClassName = undefined
}) {
  const { __ } = useTranslate()
  const isViewportXxl = useMediaQuery(mediaStyles.viewportXxl)
  const { translations, menuIsExpanded, setMenuIsExpanded } = useNavigationContext()

  return (
    <div className={cx(styles.componentActionMenu, layoutClassName)}>
      <ExpandHorizontal justification='end' expanded={!menuIsExpanded}>
        <div className={styles.contentSmall}>
          <Hamburger onClick={() => setMenuIsExpanded(true)} />
        </div>
      </ExpandHorizontal>

      <ExpandHorizontal expanded={menuIsExpanded}>
        <div className={styles.navigationButtons}>
          <LanguageSwitch {...{ translations }} />

          {showSkillsMatchButton && (
            <ShowSkillsMatchPortalFlowButton
              buttonType='secondary'
              dataX='link-to-skillsmatch'
              label={skillsMatchUserSelectionCount > 0 ? __`my-skills` : __`what-are-your-skills`}
            />
          )}

          {isViewportXxl && pageButtons?.map((x, i) => (
            <ButtonLinkPrimary
              key={i}
              href={x.href}
              label={x.label}
              dataX={x.dataX}
              layoutClassName={styles.buttonLayout}
            />
          ))}
        </div>
      </ExpandHorizontal>

      {isViewportXxl && (
        <div className={styles.fixedButtons}>
          {fixedButtons?.map(({ href, label, dataX }, i) => (
            <ButtonLinkPrimary key={i} layoutClassName={styles.buttonLayout} {...{ href, label, dataX }} />
          ))}
        </div>
      )}
    </div>
  )
}

function useFloatingProps({ id, submenuHeight }) {
  const { submenuIsOpen, onActiveSubmenuChange } = useNavigationContext()

  const { y, strategy, refs, context } = useFloating({
    open: submenuIsOpen,
    onOpenChange: handleActiveSubmenuChange,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [shift({ padding: 0 })]
  })

  const style = {
    position: strategy,
    top: y ?? 0,
    left: 0
  }

  const dismiss = useDismiss(context, {
    referencePress: false,
    outsidePress: false
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([ dismiss ])

  useHover(context)

  function handleActiveSubmenuChange(x) {
    onActiveSubmenuChange({ id, active: x, submenuHeight })
  }

  return { refs, getReferenceProps, getFloatingProps, style }
}
