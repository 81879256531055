import { Icon } from '/features/buildingBlocks/Icon'
import iconChevronDown from '/images/icons/chevron-down.raw.svg'
import { useTranslate } from '/machinery/I18n'

import styles from './LanguageSwitch.css'

export function LanguageSwitch({ translations }) {
  const current = translations.find(x => x.current)
  const { __ } = useTranslate()

  return (
    <div className={styles.component}>
      <select
        aria-label={__`language-switch-label`}
        data-x='change-language'
        disabled={(translations.length <= 1)}
        value={current.language}
        className={styles.select}
        onChange={handleLanguageChange}
      >
        {translations.map((x, i) =>
          <option
            className={styles.option}
            key={i}
            value={x.language}
          >
            {x.language.toUpperCase()}
          </option>
        )}
      </select>
      {(translations.length > 1) && <Icon icon={iconChevronDown} layoutClassName={styles.iconLayout} />}
    </div>
  )

  function handleLanguageChange(e) {
    const href = translations.find(x => x.language === e.currentTarget.value)?.href
    if (href) window.location.href = href
  }
}
