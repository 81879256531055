import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Button.css'

export function Button({ onClick, label = undefined, icon = undefined, dataX = undefined, layoutClassName = undefined }) {
  return (
    <button data-x={dataX} className={cx(styles.component, layoutClassName)} type='button' {...{ onClick }}>
      {label && <span className={styles.label}>{label}</span>}
      {icon && <Icon {...{ icon }} layoutClassName={styles.iconLayout} />}
    </button>
  )
}
