import { animated, useTransition } from '@react-spring/web'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { useMediaQuery } from '@kaliber/use-media-query'

import { routeMap } from '/routeMap'

import ImageCover from '/features/buildingBlocks/ImageCover.universal'
import { Icon } from '/features/buildingBlocks/Icon'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Submenu.css'

import iconArrow from '/images/icons/arrow.raw.svg'

export function SubmenuDesktop({ items }) {
  const isViewportXxl = useMediaQuery(mediaStyles.viewportXxl)
  const chunkSize = isViewportXxl ? 3 : 2

  const chunks = Array.from({ length: Math.ceil(items.length / chunkSize) }, (_, i) =>
    items.slice(i * chunkSize, i * chunkSize + chunkSize)
  )

  const transitions = useTransition(chunks, {
    delay: 150,
    trail: 75,
    from: { opacity: 0, y: -5 },
    enter: { opacity: 1, y: 0 },
    config: { tension: 500, friction: 50 }
  })

  return (
    <ul className={styles.componentDesktop}>
      {transitions((style, items) => (
        <animated.li className={styles.listItemDesktop} {...{ style }}>
          {items.map(({ ref, label, subtitle, image }, i) => (
            ref && <SubmenuItem key={i} linkRef={ref} {...{ label, subtitle, image }} />
          ))}
        </animated.li>
      ))}
    </ul>
  )
}

export function SubmenuMobile({ items }) {
  const transitions = useTransition(items, {
    delay: 150,
    trail: 75,
    from: { opacity: 0, y: -5 },
    enter: { opacity: 1, y: 0 },
    config: { tension: 500, friction: 50 }
  })

  return (
    <ul className={styles.componentMobile}>
      {transitions((style, { ref, label, subtitle, image }) => (
        <animated.li className={styles.listItemMobile} {...{ style }}>
          {ref && <SubmenuItem linkRef={ref} {...{ label, subtitle, image }} />}
        </animated.li>
      ))}
    </ul>
  )
}

function SubmenuItem({ linkRef, label, image, subtitle = undefined }) {
  return (
    <div className={styles.componentItem}>
      <div className={styles.image}>
        <ImageCover aspectRatio={1 / 1} layoutClassName={styles.imageLayout} {...{ image }} />
      </div>

      <ItemDetails layoutClassName={styles.itemDetailsLayout} {...{ linkRef, label, subtitle }} />

      <span className={styles.arrowIconContainer}>
        <Icon icon={iconArrow} />
      </span>
    </div>
  )
}

function ItemDetails({ linkRef, label, subtitle, layoutClassName = undefined }) {
  const href = determineDocumentPathSync({ document: linkRef, routeMap })

  return (
    <div className={cx(styles.componentItemDetails, layoutClassName)}>
      <h3 className={styles.title}>
        <a data-x='link-in-menu' className={styles.anchor} {...{ href }}>
          {label}
        </a>
      </h3>

      {subtitle && (
        <div className={styles.subtitleContainer}>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
      )}
    </div>
  )
}
