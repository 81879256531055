import { FloatingOverlay as FloatingOverlayBase } from '@floating-ui/react'
import { useMediaQuery } from '@kaliber/use-media-query'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'

import { routeMap } from '/routeMap'

import { NavigationContextProvider, useNavigationContext } from '/features/pageOnly/menu/NavigationContext'
import { ContainerFlexibleLg } from '/features/buildingBlocks/Container'
import { MenuDesktop } from '/features/pageOnly/menu/MenuDesktop'
import { MenuMobile } from '/features/pageOnly/menu/MenuMobile'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Menu.css'

export default function Menu({
  menuItems,
  translations,
  currentLocation,
  logoHref,
  pageButtons,
  fixedButtons,
  skillsMatchUserSelectionCount,
  showSkillsMatchButton = false
}) {
  const items = React.useMemo(
    () => {
      return menuItems.map(item => mapMenuItem({ item, currentLocation }))
    },
    [menuItems, currentLocation]
  )

  return (
    <NavigationContextProvider {...{ translations }}>
      <Navigation {...{ items, logoHref, pageButtons, fixedButtons, skillsMatchUserSelectionCount, showSkillsMatchButton }} />
    </NavigationContextProvider>
  )
}

function Navigation({ items, logoHref, pageButtons, fixedButtons, skillsMatchUserSelectionCount, showSkillsMatchButton }) {
  const isMounted = useRenderOnMount()
  const isViewportXl = useMediaQuery(mediaStyles.viewportXl) ?? true
  const { submenuIsOpen, mobileMenuIsExpanded } = useNavigationContext()
  const isOpen = submenuIsOpen || mobileMenuIsExpanded

  return (
    <FloatingOverlayBase lockScroll={mobileMenuIsExpanded} className={cx(styles.componentNavigation, isOpen && styles.isOpen)}>
      {isMounted &&
      <ContainerFlexibleLg>
        {isViewportXl
          ? <MenuDesktop {...{ items, logoHref, pageButtons, fixedButtons, skillsMatchUserSelectionCount, showSkillsMatchButton }} />
          : <MenuMobile {...{ items, logoHref, fixedButtons, skillsMatchUserSelectionCount, showSkillsMatchButton }} />
        }
      </ContainerFlexibleLg>
      }
    </FloatingOverlayBase>
  )
}

function mapMenuItem({ item, currentLocation }) {
  if (['referenceWithLabel', 'submenuItem'].includes(item._type)) {
    const href = item?.ref && determineDocumentPathSync({ document: item.ref, routeMap })

    return { ...item, href, isActive: currentLocation === href }
  }

  const submenuItems = item.submenuItems?.map(x => mapMenuItem({ item: x, currentLocation })) || []

  return { ...item, submenuItems, isActive: submenuItems.some(x => x.isActive) }
}
