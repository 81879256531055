import { useMediaQuery } from '@kaliber/use-media-query'

import { useScrollDirection } from '/machinery/useScrollDirection'

import mediaStyles from '/cssGlobal/media.css'

const NavigationContext = React.createContext(null)

export function NavigationContextProvider({ translations, children }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false
  const [menuIsExpanded, setMenuIsExpanded] = React.useState(true)
  const [mobileMenuIsExpanded, setMobileMenuIsExpanded] = React.useState(false)
  const [activeSubmenu, setActiveSubmenu] = React.useState({
    id: undefined,
    label: undefined,
    active: false,
    items: undefined,
    submenuHeight: 0
  })
  const submenuIsOpen = activeSubmenu.active

  useScrollDirection({
    deltaThreshold: 50,
    scrollThreshold: 50,
    callback({ direction, metScrollThreshold }) {
      if (direction === 1) setMenuIsExpanded(false)
      else if (!metScrollThreshold) setMenuIsExpanded(true)
    }
  })

  React.useEffect(
    () => {
      setActiveSubmenu(prevState => ({ ...prevState, active: false }))
      setMobileMenuIsExpanded(false)
    },
    [isViewportLg]
  )

  return (
    <NavigationContext.Provider
      value={{
        translations,
        menuIsExpanded,
        setMenuIsExpanded,
        activeSubmenu,
        submenuIsOpen,
        onActiveSubmenuChange,
        mobileMenuIsExpanded,
        setMobileMenuIsExpanded
      }}
      {...{ children }}
    />
  )

  function onActiveSubmenuChange({ id, active, submenuHeight = 0, items = undefined, label = undefined }) {
    setActiveSubmenu({ id, active, label, items, submenuHeight })
  }
}

export function useNavigationContext() {
  return React.useContext(NavigationContext)
}
